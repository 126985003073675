<template>
	<div>
		<p class="ppp">文章发布</p>
		<br />
		<el-form :model="form">
			<el-form-item label="标题:">
				<el-input v-model="form.title" placeholder="请输入文章标题(5-20字)"
					style="width: calc(60vw - 300px);float:left;">
				</el-input>
			</el-form-item>
			<el-form-item label="作者:">
				<el-select v-model="form.author" placeholder="请选择发布文章的作者" style="width: calc(60vw - 300px);float:left;">
					<el-option class="option66" v-for="item in options" :key="item.GUserID" :label="item.GuaName"
						:value="item.GUserID">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="内容:"></el-form-item>
			<el-form-item>
				<editor v-model="form.content" @change="change" @img="img"
					style="overflow:auto;width: calc(100vw - 300px);"></editor>
			</el-form-item>
		</el-form>
		<br />
		<el-button type="success" @click="add()">提交</el-button>
	</div>
</template>

<script>
	import {
		pushNews,
		pushUser
	} from '@/api/adminApi.js'
	import editor from '@/views/utils/editor'
	export default {
		components: {
			editor
		},
		data() {
			return {
				form: { //提交文章的form表单
					title: '',
					author: '',
					content: ''
				},
				options: [], //发布文章作者数组
				urlArr: [], //图片地址
			}
		},
		created() {
			this.pushUser(); //获取发布文章的作者列表
		},
		methods: {
			change(data) { //富文本回调函数，修改后的文章内容
				this.form.content = data;
			},
			img(url) { //插入图片后返回的图片地址
				this.urlArr.push(url);
			},
			async add() { //点击提交按钮
				if (this.form.title.length < 5) {
					this.$message.error('请补全标题');
				} else if (!this.form.author) {
					this.$message.error('请选择作者');
				} else if (this.form.content.length < 18) {
					this.$message.error('请补齐文章内容');
				} else {
					var div = document.createElement('div'); //创建一个div
					div.innerHTML = this.form.content; //将文章内容添加到div中
					var img = div.getElementsByTagName('img'); //查找div中所有img标签
					let imgStr = ''; //设置拼接图片地址的字符串
					img.forEach((item, index) => { //循环查找到的img标签数组
						if (img.length - 1 > index) { //进行拼接
							imgStr = imgStr + item.src + ',';
						} else {
							imgStr = imgStr + item.src;
						}
					})
					var author = this.options.filter(item => item.GUserID == this.form.author); //根据选择的作者id获取作者信息
					const data = { //请求携带的参数
						GUserID: author[0].GUserID, //作者id
						author: author[0].GuaName, //作者昵称
						title: this.form.title, //标题
						content: this.form.content, //文章内容
						type: img.length >= 3 ? 3 : (img.length == 0 ? 3 : (img.length == 1 ? 2 : 2)), //文章类型
						arrImage: imgStr //拼接的图片地址
					}
					let res = await pushNews(data); //发送请求
					if (res.status.code == 1) {
						this.$message.success("文章发布成功！");
						location.reload();
					} else {
						this.$message.error(res.status.msg);
					}
				}
			},
			async pushUser() { //获取发布文章的作者列表
				let res = await pushUser(); //请求接口
				this.options = res.data; //获得文章作者数组
			}
		}
	}
</script>
<style>
	.w-e-text-container {
		z-index: 100 !important;
	}

	.w-e-toolbar {
		z-index: 200 !important;
	}
</style>
